import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'call-button',
  templateUrl: './call-button.component.html',
  styleUrls: ['./call-button.component.scss']
})
export class CallButtonComponent implements OnInit {

  @Input() size: number = 40;
  @Input() disabled: boolean = false;
  @Input() isTransfer: boolean = false;
  @Output() click: EventEmitter<void> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  public onClick() {
    this.click.emit();
  }

}
