import { createAction, union } from "@ngrx/store";
import { AccountInfo, AccountTokens, SessionDTO, SipCredentials, SubscriptionLite } from '../states-models/account.state.model';

export const saveTokens = createAction(
  "[Account Auth] Save Tokens",
  (tokens: AccountTokens) => ({ tokens })
);

export const saveUsername = createAction(
  "[Account] Save Username",
  (username: string) => ({ username })
);

export const savePbxAndCompanyIds = createAction(
  "[Account] Save pbx & company ids",
  (pbxId: string, companyId: string) => ({ pbxId, companyId })
);

export const saveSubscription = createAction(
  "[Account] Save subscription",
  (subscription: SubscriptionLite) => ({ subscription })
);

export const saveSession = createAction(
  '[Account] Save Session',
  (session: SessionDTO) => ({ session })
);

export const saveOutboundNumber = createAction(
  '[Account] Save Outbound Number',
  (number: string) => ({ number })
);

export const saveSipCredentials = createAction(
  "[Account] Save SIP Credentials",
  (sipCredentials: SipCredentials) => ({ sipCredentials })
);

export const logout = createAction(
  "[Account] Logout"
);

export const saveUserInfo = createAction(
  "[Account] Save User Info",
  (userInfo: any) => ({userInfo})
)


const actions = {
  saveTokens,
  saveUsername,
  savePbxAndCompanyIds,
  saveSession,
  saveOutboundNumber,
  saveSipCredentials,
  saveSubscription,
  saveUserInfo,
  logout
};

const accountActions = union(actions);

export type AccountActions = typeof accountActions;